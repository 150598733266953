@use "../../common/screen";

.fest-banner {
  display: flex;
  flex-direction: column;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  padding: 36px var(--size-container-sidegap) 88px;

  @include screen.from(screen.$md) {
    display: grid;
    grid-template:
      'heading date'
      'text   address'
      'button logos'
      / 1fr 1fr;
    align-items: center;
    column-gap: 20px;
    align-content: start;
    padding: 24px 30px 88px 50px;
  }

  @include screen.from(screen.$xl) {
    padding: 30px 100px 100px;
  }
}

.fest-banner__heading {
  font-weight: 600;
  font-size: 60px;
  line-height: 1;
  margin-bottom: 20px;
  text-align: center;

  span {
    display: block;
  }

  @include screen.from(screen.$md) {
    grid-area: heading;
    font-size: 50px;
    margin-bottom: 0;
  }

  @include screen.from(screen.$xl) {
    font-size: 70px;
  }
}

.fest-banner__heading-text {
  display: block;
  background-image: linear-gradient(0turn, rgb(206 0 35 / 42%) 0%, rgb(242 0 41 / 100%) 100%);
  background-clip: text;
  color: transparent;
}

.fest-banner__heading-year {
  display: block;
  background-image: linear-gradient(
    to bottom,
    rgb(255 255 255 / 100%) 56%,
    rgb(255 255 255 / 0%) 100%
  );
  background-clip: text;
  color: transparent;
}

.fest-banner__text {
  display: none;

  @include screen.from(screen.$md) {
    display: block;
    grid-area: text;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;
    margin-left: 75px;
  }
}

.fest-banner__datetime {
  font-size: 30px;
  margin-bottom: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  @include screen.from(screen.$md) {
    grid-area: date;
    font-size: 40px;
    text-align: left;
    margin-bottom: 0;
  }

  @include screen.from(screen.$xl) {
    font-size: 60px;
  }
}

.fest-banner__date {
  display: block;
  background-image: linear-gradient(0turn, rgb(206 0 35 / 42%) 0%, rgb(242 0 41 / 100%) 100%);
  background-clip: text;
  color: transparent;
}

.fest-banner__time {
  display: block;
  background-image: linear-gradient(0turn, rgb(206 0 35 / 42%) 0%, rgb(242 0 41 / 100%) 100%);
  background-clip: text;
  color: transparent;
}

.fest-banner__address {
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;

  @include screen.from(screen.$md) {
    grid-area: address;
    text-align: left;
    font-size: 20px;
    margin-bottom: 16px;
  }

  @include screen.from(screen.$xl) {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.fest-banner__logos {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }

  @include screen.from(screen.$md) {
    margin: 0;
    grid-area: logos;
  }

  @include screen.from(screen.$xl) {
  max-width: 450px;
  }
}

.fest-banner__button {
  display: none;

  @include screen.from(screen.$md) {
    grid-area: button;
    display: block;
    align-self: center;
    width: 100%;
    max-width: 240px;
    justify-self: center;
    background: #d00021;
    border-color: #fff;
  }
}
